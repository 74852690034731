import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js'
import 'chartjs-adapter-luxon'

import zoomPlugin from 'chartjs-plugin-zoom'

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  PointElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  zoomPlugin
)
